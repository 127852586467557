import styled from "styled-components/macro";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { basicStyles } from "./defaultStyles";

export const formStyles = {
    inputBG: "hsl(0deg 0% 25%)",
    formShadow: "0 10px 10px 1px #211f1f",
    formBorder: "1px solid #757575",
    burwoodGreen: "#446857",
    error: "#9c4a4a"
};

export const buttonStyles = `
    border: 1px solid #757575;
    border-radius: 5px;
    box-shadow: ${formStyles.formShadow};
    &:focus, &:hover, &:active, &:active:focus {
        background-color: #1f6951 !important;
        border-color: #549965 !important;
        outline: 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
            0 0 8px rgba(102, 175, 233, 0.6) !important;
    }
`;

const defaultStyle = `
    border: 1px solid #757575;
    border-radius: 5px;
    background-color: ${formStyles.inputBG};
    box-shadow: ${formStyles.formShadow};
    padding-left: 10px;
    padding-right: 10px;
    &:focus, &:hover {
        border-color: #549965;
        outline: 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
            0 0 8px rgba(102, 175, 233, 0.6);
    }
`;

export const DefaultForm = styled(Col)`
    ${defaultStyle}
`;

export const FormContainer = styled(Col)`
    padding: 15px;
    border-radius: 5px;
    background-color: ${basicStyles.backgroundColor};
    box-shadow: ${formStyles.formShadow};
    min-height: 85vh;
    border: ${basicStyles.border};
`;

export const FormHeader = styled(Col)`
    text-align: center;
    padding: 15px;
    font-weight: 400 !important;
    font-size: 48px;
`;

export const FormInfo = styled(Col)`
    border: 1px solid #757575;
    padding: 45px;
    text-align: center;
    border-radius: 5px;
    background-color: ${formStyles.inputBG};
    box-shadow: 0 10px 10px 1px #211f1f;
    letter-spacing: 1px;
`;

export const FormLabel = styled.label`
    float: left;
    width: 100%;
`;

export const FormInput = styled.input`
    ${defaultStyle}
    height: ${(props) => props.height || "38px"};
    width: ${(props) => props.width || "100%"};
    color: ${(props) => props.theme.textColor};
    letter-spacing: ${(props) => props.theme.spacing};
    font-weight: ${(props) => props.theme.weight};
    margin-top: ${(props) => (props.margin ? props.margin : "0px")};
    margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
`;

export const HiddenFormInput = styled.input`
    opacity: 0;
    float: left;
    margin-left: 20%;
    margin-top: -38px;
    pointer-events: none;
`;

export const FormCheckbox = styled.input`
    ${defaultStyle}
    width: 40px;
    height: 40px;
    border-radius: 14%;
    vertical-align: middle;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    text-align: center;
    margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
    &:checked {
        background-color: ${formStyles.burwoodGreen};
        :before {
            content: "✓";
            color: white;
            font-size: 25px;
        }
    }
`;

export const FormTextArea = styled.textarea`
    ${defaultStyle}
    padding-top: 10px;
    color: ${(props) => props.theme.textColor};
    letter-spacing: ${(props) => props.theme.spacing};
    font-weight: ${(props) => props.theme.weight};
    height: ${(props) => props.height || "200px"};
    width: ${(props) => props.width || "100%"};
`;

export const FormSubmit = styled(Button)`
    ${buttonStyles}
    background-color: ${formStyles.burwoodGreen};
    color: ${(props) => props.theme.textColor};
    letter-spacing: ${(props) => props.theme.spacing};
    font-weight: ${(props) => props.theme.weight};
    height: ${(props) => props.height || "38px"};
    width: ${(props) => props.width || "100%"};
`;

export const FormDelete = styled(Button)`
    ${buttonStyles}
    background-color: ${formStyles.error};
    color: ${(props) => props.theme.textColor};
    letter-spacing: ${(props) => props.theme.spacing};
    font-weight: ${(props) => props.theme.weight};
    height: ${(props) => props.height || "38px"};
    width: ${(props) => props.width || "100%"};
`;

export const selectStyles = {
    control: (styles, { isFocused }) => ({
        ...styles,
        backgroundColor: formStyles.inputBG,
        marginBottom: "10px",
        border: isFocused ? "1px solid #549965" : "1px solid #757575",
        outline: isFocused ? "0" : "inherit",
        boxShadow: isFocused
            ? "inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)"
            : "0 10px 10px 1px #211f1f",
        ":hover": {
            borderColor: "#549965",
            outline: "0",
            boxShadow:
                "inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)"
        }
    }),
    menu: (styles) => {
        return {
            ...styles,
            backgroundColor: "hsl(0deg 0% 28%)",
            marginTop: "0px",
            padding: "0px",
            border: "1px solid gray"
        };
    },

    menuList: (styles) => {
        return {
            ...styles,
            "::-webkit-scrollbar": {
                width: "6px",
                height: "6px"
            },
            "::-webkit-scrollbar-corner": {
                background: "rgba(0, 0, 0, 0)"
            },
            "::-webkit-scrollbar-track": {
                WebkitBoxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
                WebkitBorderRadius: "10px",
                borderRadius: "10px"
            },
            "::-webkit-scrollbar-thumb": {
                WebkitBorderRadius: "10px",
                borderRadius: "10px",
                background: "rgba(255, 255, 255, 0.3)",
                WebkitBoxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.0)"
            },
            "::-webkit-scrollbar-thumb:hover": {
                background: "#555"
            }
        };
    },
    dropdownIndicator: (styles) => {
        return {
            ...styles,
            ":hover": {
                color: "white"
            }
        };
    },
    input: (styles) => {
        return {
            ...styles,
            color: "white"
        };
    },
    placeholder: (styles) => {
        return {
            ...styles,
            color: "grey"
        };
    },
    singleValue: (styles) => {
        return {
            ...styles,
            color: "white"
        };
    },
    option: (styles, { isDisabled }) => {
        return {
            ...styles,
            backgroundColor: "hsl(0deg 0% 28%)",
            paddingLeft: "10px",
            color: "white",
            cursor: isDisabled ? "not-allowed" : "default",
            ":hover": {
                backgroundColor: "#446857"
            }
        };
    }
};
