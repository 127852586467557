import React, { Component } from "react";
import { connect } from "react-redux";
import DefaultComponent from "../util/StyledComponents/DefaultComponent";

const mapStateToProps = (state) => {
  return {
    user: state.userReducer,
  };
};

class App extends Component {
  render() {
    if (this.props.user.loading) {
      return <DefaultComponent />;
    }
    return (
      <DefaultComponent>
        <div>
          Welcome {this.props.user.data.name}. You Are Successfully Logged In
        </div>
      </DefaultComponent>
    );
  }
}

const ConnectedApp = connect(mapStateToProps)(App);

export default ConnectedApp;
