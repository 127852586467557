import axios from "./axiosConfig";

const server_env = process.env.REACT_APP_BACKEND;

const Servicenow = {
    queryTable: (tableName, queryObj) => {
        return new Promise(async (resolve, reject) => {
            queryObj = { ...queryObj, tableName };

            try {
                const response = await axios.post(`${server_env}/servicenow/get_tables`, queryObj);
                if (response.status === 200) {
                    return resolve(response.data);
                } else {
                    return reject(response.status);
                }
            } catch (e) {
                console.log(e);
                return reject(e);
            }
        });
    },
    postTable: (tableName, queryObj) => {
        return new Promise(async (resolve, reject) => {
            queryObj = { ...queryObj, tableName };

            try {
                const response = await axios.post(`${server_env}/servicenow/modify_tables`, queryObj);
                if (response.status === 200) {
                    return resolve(response.data);
                } else {
                    console.log(response);
                    return reject(response.status);
                }
            } catch (e) {
                console.log(e);
                return reject(e);
            }
        });
    }
};

export default Servicenow;
