import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, withRouter } from "react-router-dom";
import styled from "styled-components";
import { logoutSession } from "../../js/actions/userActions";
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import { Icon as BaseIcon } from "react-icons-kit";
import { dashboard } from "react-icons-kit/fa/dashboard";
import { ticket } from "react-icons-kit/fa/ticket";
import { barChart } from "react-icons-kit/fa/barChart";
import { hddO } from "react-icons-kit/fa/hddO";
import { feed } from "react-icons-kit/fa/feed";
import { folderOpenO } from "react-icons-kit/fa/folderOpenO";
import { signIn } from "react-icons-kit/fa/signIn";

function mapDispatchToProps(dispatch) {
    return {
        logoutSession: () => dispatch(logoutSession())
    };
}

const mapStateToProps = (state) => {
    return { user: state.userReducer };
};

const Icon = (props) => <BaseIcon size={32} icon={props.icon} />;

const SideNavBar = styled(SideNav)`
    background-color: #556c60 !important;
`;

const LogoutButton = styled(NavItem)`
    position: absolute !important;
    bottom: 10px;
    width: 100%;
`;

class NavBar extends Component {
    logoutSession = async () => {
        this.props.logoutSession();
    };

    render() {
        return (
            <BrowserRouter>
                <Route
                    render={({ location, history }) => (
                        <React.Fragment>
                            <SideNavBar
                                onSelect={(selected) => {
                                    const to = "/" + selected;
                                    //   if (location.pathname !== to) {
                                    this.props.history.push(to);
                                    //   }
                                }}
                                className="sidenav-bar"
                            >
                                <SideNav.Toggle />
                                <SideNav.Nav defaultSelected=" ">
                                    <NavItem eventKey=" ">
                                        <NavIcon>
                                            <Icon icon={dashboard} />
                                        </NavIcon>
                                        <NavText>DASHBOARD</NavText>
                                    </NavItem>
                                    <NavItem eventKey="tickets">
                                        <NavIcon>
                                            <Icon icon={ticket} />
                                        </NavIcon>
                                        <NavText>TICKETS</NavText>
                                    </NavItem>
                                    <NavItem eventKey="reports">
                                        <NavIcon>
                                            <Icon icon={barChart} />
                                        </NavIcon>
                                        <NavText>REPORTS</NavText>
                                    </NavItem>
                                    <NavItem eventKey="devices">
                                        <NavIcon>
                                            <Icon icon={hddO} />
                                        </NavIcon>
                                        <NavText>DEVICES</NavText>
                                    </NavItem>
                                    <NavItem eventKey="events">
                                        <NavIcon>
                                            <Icon icon={feed} />
                                        </NavIcon>
                                        <NavText>EVENTS</NavText>
                                    </NavItem>
                                    <NavItem eventKey="requests/open_cloud_ticket">
                                        <NavIcon>
                                            <Icon icon={folderOpenO} />
                                        </NavIcon>
                                        <NavText>OPEN TICKET</NavText>
                                    </NavItem>
                                    <LogoutButton onClick={this.logoutSession}>
                                        <NavIcon>
                                            <Icon icon={signIn} />
                                        </NavIcon>
                                        <NavText>LOGOUT</NavText>
                                    </LogoutButton>
                                </SideNav.Nav>
                            </SideNavBar>
                        </React.Fragment>
                    )}
                />
            </BrowserRouter>
        );
    }
}

const ConnectedNavBar = connect(mapStateToProps, mapDispatchToProps)(NavBar);

export default withRouter(ConnectedNavBar);
