import axios from "./axiosConfig";

const server_env = process.env.REACT_APP_BACKEND;

const BCSDatabase = {
    queryData: (route, queryObj) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.post(`${server_env}${route}`, queryObj);
                if (response.status === 200) {
                    return resolve(response.data);
                } else {
                    return reject(response.status);
                }
            } catch (e) {
                console.log(e);
                return reject(e);
            }
        });
    }
};

export default BCSDatabase;
