import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import combinedReducers from "./reducers/combinedReducers";
import { fetchSession } from "../js/actions/userActions";

const store = createStore(combinedReducers, applyMiddleware(thunk));

//fetches session before rendering app
store.dispatch(fetchSession());

export default store;
