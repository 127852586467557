import React, { Component } from "react";

//Axios Controller Imports
import BCSDatabase from "../../js/axios/bcsDatabase";
import Servicenow from "../../js/axios/servicenow";

//Component Imports
import CloudRequestForm from "./CloudRequestForm";
import { PageContainer, CenteredRow } from "../util/StyledComponents/PageContainer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import LoadingSymbol from "../util/Loading/LoadingSymbol";
import { FormContainer, FormHeader, FormInfo } from "../util/StyledComponents/Form";
import Col from "react-bootstrap/Col";

//Util Imports
import { CloudUtil } from "../../js/cloud/util";

let requestOptions = [
    {
        name: "I have a general inquiry",
        id: "General Inquiry"
    },
    {
        name: "I have a billing inquiry",
        id: "Billing Inquiry"
    },
    {
        name: "I have an idea for a Portal enhancement",
        id: "Portal Enhancement"
    },
];

class OpenTicket extends Component {
    state = {
        cloudProjects: [],
        cloudAccounts: [],
        adminServices: false,
        providedService: null,
        loadingAccounts: true,
        loadingProjects: true,
        loadingServices: true,
        requestOptions: requestOptions
    };

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props && this?.props?.user_info) {
            this.setState({
                requestOptions: this.state.requestOptions
            });

            //Fetching Cloud Accounts for Request Selection
            BCSDatabase.queryData("/cloud_accounts", {
                companyCode: this.props.user_info.company_code
            })
                .then((response) => {
                    this.setState({
                        cloudAccounts: response.accounts,
                        loadingAccounts: false
                    });
                })
                .catch((e) => {
                    this.setState({
                        loadingAccounts: false
                    });
                });

            //Fetching Cloud Projects for Request Selection
            BCSDatabase.queryData("/big_query/get_query", {
                view: "project_list"
            })
                .then((response) => {
                    let filteredProjects = CloudUtil.removeExportProjects(response, this.props.user_info.company_code);
                    this.setState({
                        cloudProjects: CloudUtil.removeUnknownProjects(filteredProjects),
                        loadingProjects: false
                    });
                })
                .catch((e) => {
                    this.setState({
                        loadingProjects: false
                    });
                });

            // Fetching Company's Provided Services List in SNOW
            Servicenow.queryTable("u_provided_services", {
                u_company: this.props.user_info.company,
                bypassCompany: true,
                u_installed: true
            })
                .then((response) => {
                    let p_services = response?.results ?? [];

                    p_services = p_services.map((service) => service.u_service);

                    if (p_services.includes("GCP Admin Services") && p_services.includes("GCP Reseller")) {
                        this.setState({
                            providedService: "GCP Reseller",
                        });
                    } else if (p_services.includes("GCP Admin Services")) {
                        this.setState({
                            providedService: "GCP Admin Services",
                            adminServices: true
                        });
                    } else if (p_services.includes("GCP Reseller")) {
                        this.setState({
                            providedService: "GCP Reseller",
                        });
                    }

                    this.setState({
                        loadingServices: false
                    });
                })
                .catch((e) => {
                    this.setState({
                        loadingServices: false
                    });
                });
        }
    }

    render() {
        return (
            <PageContainer>
                <Container>
                    <Row>
                        <FormContainer>
                            <CenteredRow>
                                <Col lg={5} md={10} sm={10}>
                                    <FormHeader>Cloud Request</FormHeader>
                                </Col>
                                <Col lg={5} md={10} sm={10}>
                                    <FormInfo>
                                        Use this ticket to request information or action related to a BCS service.
                                    </FormInfo>
                                </Col>
                            </CenteredRow>
                            {this.state.loadingAccounts || this.state.loadingServices || this.state.loadingProjects ? (
                                <LoadingSymbol></LoadingSymbol>
                            ) : (
                                <CloudRequestForm
                                    user_info={this.props.user_info}
                                    cloudOptions={this.state.requestOptions}
                                    cloudProjects={this.state.cloudProjects}
                                    cloudAccounts={this.state.cloudAccounts}
                                    adminServices={this.state.adminServices}
                                    providedService={this.state.providedService}
                                />
                            )}
                        </FormContainer>
                    </Row>
                </Container>
            </PageContainer>
        );
    }
}

export default OpenTicket;
