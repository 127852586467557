import axios from "axios";
import Cookies from "js-cookie";

const server_env = process.env.REACT_APP_BACKEND;

const Axios = () => {
    const defaultOptions = {
        baseURL: server_env,
        withCredentials: true,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Credentials: "include"
        }
    };

    // Create instance
    let instance = axios.create(defaultOptions);

    // Set the AUTH token for any request
    instance.interceptors.request.use(function (config) {
        const cookie = Cookies.get("XSRF-TOKEN");
        config.headers["X-XSRF-TOKEN"] = cookie;
        return config;
    });

    return instance;
};

export default Axios();
