export const combineInternalRequestFields = (cloud, request) => {
    request.relatedAccount && (request.relatedAccount = request?.relatedAccount?.account);
    request.billingAccount = request?.billingAccount?.account;
    request.accountName = request?.accountName?.name;

    let titleObj, fieldObj;
    cloud ? (titleObj = "newCloudRequestDescrTitles") : (titleObj = "newRequestDescrTitles");
    cloud ? (fieldObj = "newCloudRequestDescrFields") : (fieldObj = "newRequestDescrFields");
    !request.description
        ? (request.description = "ADDITIONAL INFORMATION: ")
        : (request.description += "\n\n ADDITIONAL INFORMATION: ");
    for (let specialField in requestFormatter[fieldObj]) {
        if (request[specialField]) {
            request.description += "\n" + requestFormatter[titleObj][specialField] + ": " + request[specialField];
        }
    }
    return request;
};

const requestFormatter = {
    newRequestDescrFields: {
        businessJustification: null,
        risk: null,
        client: null,
        impact: null,
        scope: null,
        owner: null,
        preferredOwner: null,
        type: null,
        associatedTasks: null,
        needsFurtherScoping: null,
        mRR: null,
        notes: null
    },
    newRequestDescrTitles: {
        businessJustification: "Business Justification",
        risk: "Risk",
        client: "Client",
        impact: "Impact",
        scope: "Scope",
        owner: "Owner",
        preferredOwner: "Preferred Owner",
        type: "Type",
        associatedTasks: "Associated Tasks",
        needsFurtherScoping: "Needs Further Scoping",
        mRR: "MRR",
        notes: "Notes"
    },
    newCloudRequestDescrFields: {
        projectName: null,
        projectOwner: null,
        projectOwnerEmail: null,
        departmentName: null,
        fundNumber: null,
        fundingAmount: null,
        creditAmount: null,
        phiInfo: null,
        additionalUsers: null,
        email: null,
        accountName: null,
        billingAccount: null,
        purchaseOrder: null,
        relatedProject: null,
        relatedAccount: null,
        office: null,
        programName: null,
        officerName: null,
        officerEmail: null,
        contactName: null,
        contactEmail: null,
        billingName: null,
        billingEmail: null
    },
    newCloudRequestDescrTitles: {
        projectName: "Project Name",
        projectOwner: "Project Owner",
        projectOwnerEmail: "Project Owner Email",
        departmentName: "Department Name",
        fundNumber: "Fund Number",
        fundingAmount: "Funding Amount",
        creditAmount: "Credit Amount",
        phiInfo: "PHI Information Will Be Stored?",
        additionalUsers: "Additional User Names and Emails",
        email: "Email",
        accountName: "Billing Account Name",
        billingAccount: "Billing Account Number",
        purchaseOrder: "Purchase Order",
        relatedProject: "Related Project",
        relatedAccount: "Related Account",
        office: "NIH Institute/Center/Office",
        programName: "Program/Project Name",
        officerName: "Program Officer Name",
        officerEmail: "Program Officer Email",
        contactName: "Primary Contact Name",
        contactEmail: "Primary Contact Email",
        billingName: "Billing Admin Name",
        billingEmail: "Billing Admin Email"
    }
};
