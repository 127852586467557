import React from "react";

export const CloudUtil = {
    removeExportProjects: (projects = [], companyCode) =>
        projects.filter((project) => !project?.project_id.includes(companyCode.toLowerCase() + "-01")),
    removeUnknownProjects: (projects = []) =>
        projects.filter(
            (project) =>
                project?.project_id !== "Unknown" && project?.project_id !== "[Charges not specified to a project]"
        )
};

export const FormUtil = {
    useFormFields: (initialValues) => {
        const [formFields, setFormFields] = React.useState(initialValues);
        const createChangeHandler = (key, checkbox) => (e) => {
            let value = e?.target?.value || e?.target?.value === "" ? e?.target?.value : e;
            if (checkbox) {
                value = value === "true";
                value = !value;
            }
            setFormFields((prev) => ({ ...prev, [key]: value }));
        };
        return { formFields, createChangeHandler };
    }
};
