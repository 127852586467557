import React from "react";
import styled from "styled-components/macro";
import Modal from "react-bootstrap/Modal";
import { FormSubmit } from "./Form";

const angular_env = process.env.REACT_APP_ANGULAR_FR;

export const ModalHeader = styled(Modal.Header)`
    background-color: #545454;
`;

export const ModalBody = styled(Modal.Body)`
    background-color: #38403c;
`;

export const ModalFooter = styled(Modal.Footer)``;

const TicketLink = styled.div`
    cursor: pointer;
`;

export const TicketModal = (props) => {
    return (
        <Modal
            show={props.modal}
            onHide={() => {
                props.ticket.sys_id && window.parent.postMessage(props.ticket, angular_env);
                return props.setModal(false);
            }}
        >
            <ModalHeader>
                <Modal.Title>{props.ticket.sys_id ? "Ticket Confirmation" : "Failed To Create Ticket"}</Modal.Title>
            </ModalHeader>
            <ModalBody>
                <TicketLink
                    onClick={() => {
                        props.ticket.sys_id && window.parent.postMessage(props.ticket, angular_env);
                        return props.setModal(false);
                    }}
                >
                    {props.ticket.sys_id
                        ? `Your ticket ${props.ticket.number} has been accepted.`
                        : "Please contact support for assistance."}
                </TicketLink>
            </ModalBody>
            <ModalFooter>
                <FormSubmit
                    width={"100px"}
                    onClick={() => {
                        props.ticket.sys_id && window.parent.postMessage(props.ticket, angular_env);
                        return props.setModal(false);
                    }}
                >
                    Close
                </FormSubmit>
            </ModalFooter>
        </Modal>
    );
};
