import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { isAuthenticated } from "../../js/auth";

const mapStateToProps = (state) => {
    return { user: state.userReducer };
};

class ProtectedRoute extends Component {
    state = { iframeData: false };

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({ iframeData: this.props.iframeData });
        }
    }

    render() {
        const { component: Component, ...props } = this.props;

        if (this.props.user.loading) {
            return <div className="App App-header"></div>;
        }

        return (
            <Route
                {...props}
                render={(props) => {
                    return isAuthenticated(this.props.user.data) ? (
                        <Component {...props} iframeData={this.state.iframeData} user_info={this.props.user.data} />
                    ) : (
                        <Redirect to="/login" />
                    );
                }}
            />
        );
    }
}

const ConnectedProtectedRoute = connect(mapStateToProps, null, null, {
    pure: false
})(ProtectedRoute);

export default ConnectedProtectedRoute;
