import React, { Component } from "react";
import styled from "styled-components";

const DefaultStyle = styled.div`
    min-height: 100vh;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: grey;
`;

class DefaultComponent extends Component {
    render() {
        return <DefaultStyle className={this.props.className}>{this.props.children}</DefaultStyle>;
    }
}

export default DefaultComponent;
