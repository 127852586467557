import React, { useState } from "react";
// import { Link } from "react-router-dom";

//Axios Controller Imports
import Servicenow from "../../js/axios/servicenow";

//Styled Components
import {
    HiddenFormInput,
    FormTextArea,
    FormLabel,
    FormSubmit,
    selectStyles
} from "../util/StyledComponents/Form";
import Select from "react-select";
import Col from "react-bootstrap/Col";
import LoadingSymbol from "../util/Loading/LoadingSymbol";
import { CenteredRow } from "../util/StyledComponents/PageContainer";
import { TicketModal } from "../util/StyledComponents/Modal";

//Util Imports
import { FormUtil } from "../../js/cloud/util";
import { combineInternalRequestFields } from "./RequestFormatter";

const server_env = process.env.REACT_APP_BACKEND;

const RequestForm = (props) => {
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [ticket, setTicket] = useState({});

    const { formFields, createChangeHandler } = FormUtil.useFormFields({
        selectedRequest: "",
        billingAccount: "",
        accountName: "",
        billingOwner: "",
        email: "",
        purchaseOrder: "",
        relatedAccount: "",
        relatedProject: "",
        creditAmount: "",
        description: "",
        projectName: "",
        projectOwner: "",
        projectOwnerEmail: "",
        departmentName: "",
        fundNumber: "",
        fundingAmount: "",
        phiInfo: false,
        additionalUsers: "",
        office: "",
        programName: "",
        officerName: "",
        officerEmail: "",
        contactName: "",
        contactEmail: "",
        billingName: "",
        billingEmail: ""
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        let requestToCreate = combineInternalRequestFields("cloud", formFields);

        props.adminServices
            ? (requestToCreate.assignment_group = "7fc641e94ffc5e4061fcc3fd0210c70c")
            : (requestToCreate.assignment_group = "9550b50213f38b00e11f57963244b009");

        requestToCreate.priority = "3";
        requestToCreate.company = props.user_info.company;
        requestToCreate.opened_by = props.user_info.sys_id;
        requestToCreate.requested_for = props.user_info.sys_id;
        requestToCreate.u_category = props.providedService;
        requestToCreate.short_description = formFields.selectedRequest.id;

        setLoading(true);

        // Fetching Company's Provided Services List in SNOW
        Servicenow.postTable("sc_request", {
            data: requestToCreate
        })
            .then((response) => {
                console.log(response);
                formFields.requestLoading = false;
                setTicket(response.result);
                setLoading(false);
                setModal(true);
            })
            .catch((e) => {
                console.log(e);
                formFields.requestLoading = false;
                setLoading(false);
                setModal(true);
            });
    };

    return (
        <>
            {loading && <LoadingSymbol />}
            <TicketModal setModal={setModal} ticket={ticket} modal={modal} server_env={server_env}></TicketModal>
            <form onSubmit={handleSubmit}>
                <CenteredRow>
                    <Col lg={4} md={10} sm={10}>
                        <FormLabel>Request Type</FormLabel>
                        <Select
                            value={formFields.selectedRequest}
                            onChange={createChangeHandler("selectedRequest")}
                            styles={selectStyles}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            options={props.cloudOptions}
                        />
                        <HiddenFormInput
                            type="text"
                            required
                            value={formFields.selectedRequest}
                            onChange={createChangeHandler("selectedRequest")}
                        />
                    </Col>
                </CenteredRow>
                <CenteredRow padding={"0px"}>
                    <Col lg={12} md={12} sm={12}>
                        {(() => {
                            switch (formFields.selectedRequest.id) {
                                case "General Inquiry":
                                    return null;
                                case "Portal Enhancement":
                                    return null;
                                case "Billing Inquiry":
                                    return (
                                        <CenteredRow padding={"0px"}>
                                            <Col lg={4} md={10} sm={10}>
                                                <FormLabel>Related Account *</FormLabel>
                                                <Select
                                                    value={formFields.relatedAccount}
                                                    onChange={createChangeHandler("relatedAccount")}
                                                    styles={selectStyles}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.account}
                                                    options={props.cloudAccounts}
                                                    required
                                                />
                                                <HiddenFormInput
                                                    type="text"
                                                    required
                                                    value={formFields.relatedAccount}
                                                    onChange={createChangeHandler("relatedAccount")}
                                                />
                                            </Col>
                                        </CenteredRow>
                                    );
                                default:
                                    return null;
                            }
                        })()}
                    </Col>
                </CenteredRow>
                <CenteredRow>
                    <Col lg={8} md={10} sm={10}>
                        <FormLabel>Request Details</FormLabel>
                        <FormTextArea
                            value={formFields.description}
                            onChange={createChangeHandler("description")}
                            height={"250px"}
                        ></FormTextArea>
                    </Col>
                </CenteredRow>
                <CenteredRow>
                    <Col lg={2} md={6} sm={6}>
                        <FormSubmit type="submit">SUBMIT</FormSubmit>
                    </Col>
                </CenteredRow>
            </form>
        </>
    );
};

export default RequestForm;
