import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchUser } from "../../js/actions/userActions";
import { fetchSSOIdentityProvider } from "../../js/actions/userActions";
import ErrorMessage from "../util/Messages/ErrorMessage";
import LoadingData from "../util/Loading/LoadingData";
import { Redirect } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import "../../index.css";
import loginImage from "../../images/login_background.jpg";
import loginArrow from "../../images/login_arrow.png";
import cloudImage from "../../images/cloud_brown.png";
import burwoodLogo from "../../images/logo_beige.png";

const theme = {
  textColor: "white",
  font: "Segoe UI",
};

const LoginContainer = styled.div`
  display: flex;
  width: 100%;
`;

const LoginImagePanel = styled.div`
  background: url(${loginImage}) no-repeat left center fixed;
  flex: 5;
  background-size: auto 110%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const LoginRightPanel = styled.div`
  background-color: #f1ebdd;
  flex: 2;
  height: 100vh;
`;

const LoginRightPanelFlex = styled.div`
  background-color: #f1ebdd;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 2;
  height: 100vh;
  width: 100%;
`;

const LoginButton = styled.input`
  background-image: url(${loginArrow});
  width: 200px;
  height: 200px;
  background-size: cover;
  filter: drop-shadow(1px 20px 0px #cac5b9);
  transition: all 0.15s ease;
  border: none;
  background-color: transparent;
  &:hover {
    filter: drop-shadow(1px 20px 0px #bca89f);
    cursor: pointer;
  }
  &:active {
    transform: scale(0.98);
    filter: drop-shadow(1px 20px 0px #918079);
  }
  &:focus {
    outline: none;
  }
`;

const LoginTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 185px;
  margin-top: -230px;
`;

const LoginTitleImage = styled.div`
  background-image: url(${cloudImage});
  background-size: cover;
  width: 75px;
  height: 40px;
`;

const LoginTitleText = styled.div`
  font-size: 35px;
  color: #a88c7e;
  margin-left: 10px;
`;

const LoginInput = styled.input`
  margin: auto;
  position: absolute;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid white;
  text-align: center;
  color: #7f6a60;
  &::placeholder {
    color: white;
  }
  &:focus {
    outline: none;
  }
`;

const UserInput = styled(LoginInput)`
  top: 46%;
  margin-left: -123px;
  width: 217px;
  font-size: 14px;
  text-indent: -30px;
  &::placeholder {
    letter-spacing: 6px;
    font-size: 16px;
  }
`;

const PassInput = styled(LoginInput)`
  top: 50%;
  margin-left: -119px;
  width: 240px;
  text-indent: -60px;
  font-size: 16px;
  opacity: ${(props) => (props.fade ? ".3" : "1")};
  transition: all 0.25s ease;
  &::placeholder {
    letter-spacing: 6px;
    font-size: 16px;
  }
`;

const BurwoodLogo = styled.div`
  background-image: url(${burwoodLogo});
  width: 180px;
  height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 17px;
  margin-top: -341px;
  right: 0;
  position: absolute;
  opacity: 0.3;
`;

const LoginText = styled.div`
  margin-right: 6px;
  margin-bottom: 3px;
  right: 0;
  bottom: 0;
  position: absolute;
  letter-spacing: 1px;
`;

const SupportText = styled(LoginText)`
  margin-left: 6px;
  left: 0;
`;

const LoadingText = styled(LoadingData)`
  color: ${(props) => props.theme.textColor};
  margin-left: 29%;
  letter-spacing: 5px;
  font-size: 18px;
`;

const ErrorText = styled(ErrorMessage)`
  color: ${(props) => props.theme.textColor};
  margin-left: 13%;
  letter-spacing: 5px;
  font-size: 18px;
  text-transform: uppercase;
  width: 700px;
  text-align: center;
`;

const Checkbox = (props) => <input type="checkbox" {...props} />;

const StyledCheckbox = styled(Checkbox)`
  right: 0;
  bottom: 0;
  vertical-align: middle;
`;

const CheckboxLabel = styled.label`
  margin-right: 6px;
  margin-bottom: 43px;
  right: 0;
  bottom: 0;
  position: absolute;
  color: white;
  letter-spacing: 1px;
  font-size: 14px;
`;

function mapDispatchToProps(dispatch) {
  return {
    fetchUser: (user) => dispatch(fetchUser(user)),
    fetchSSOIdentityProvider: (email) =>
      dispatch(fetchSSOIdentityProvider(email)),
  };
}

const mapStateToProps = (state) => {
  return { user: state.userReducer };
};

class LoginForm extends Component {
  state = { ssoChecked: false };

  handleCheckboxChange = (event) => {
    this.setState({ ssoChecked: event.target.checked });
  };

  handleNameChange = (event) => {
    this.setState({ username: event.target.value });
  };

  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    let user = {
      username: this.state.username,
      password: this.state.password,
    };
    if (!this.state.ssoChecked) {
      this.props.fetchUser(user);
    } else {
      this.props.fetchSSOIdentityProvider(user.username);
    }
  };

  render() {
    const { error, loading, data, sso } = this.props.user;

    if (sso) {
      // TODO Once I set up env var paths in redux
      // window.location = this.props.basePAth + '/sso/' + this.state.username;
      window.location = "https://bcs-dev.burwood.us/sso/" + this.state.username;
    }

    if (data && !error) {
      return <Redirect to="/" />;
    }

    return (
      <ThemeProvider theme={theme}>
        <LoginContainer>
          <LoginImagePanel>
            <BurwoodLogo />
            <LoginText>REGISTER | FORGOT PASSWORD?</LoginText>
            <SupportText>SUPPORT @ 312-592-8387</SupportText>
            <CheckboxLabel>
              SSO
              <StyledCheckbox
                checked={this.state.ssoChecked}
                onChange={this.handleCheckboxChange}
              />
            </CheckboxLabel>
            <LoadingText message={"AUTHENTICATING..."} loading={loading} />
            <ErrorText error={error} />
          </LoginImagePanel>
          <form onSubmit={this.handleSubmit} style={{ display: "contents" }}>
            <LoginRightPanel>
              <UserInput
                type="text"
                value={this.state.username || ""}
                onChange={this.handleNameChange}
                required
                placeholder="USER"
                data-lpignore="true"
              />
              <PassInput
                type="password"
                value={this.state.password || ""}
                onChange={this.handlePasswordChange}
                required
                placeholder="PASS"
                data-lpignore="true"
                fade={this.state.ssoChecked}
                disabled={this.state.ssoChecked}
              />
              <LoginRightPanelFlex>
                <LoginTitleContainer>
                  <LoginTitleImage />
                  <LoginTitleText>service portal</LoginTitleText>
                </LoginTitleContainer>
                <LoginButton type="submit" value="" />
              </LoginRightPanelFlex>
            </LoginRightPanel>
          </form>
        </LoginContainer>
      </ThemeProvider>
    );
  }
}

const ConnectedLoginForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm);

export default ConnectedLoginForm;
