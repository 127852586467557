import Axios from "../axios/axiosConfig";

export const fetchUserBegin = () => ({
    type: "FETCH_USER_BEGIN"
});

export const fetchUserSuccess = (user) => ({
    type: "FETCH_USER_SUCCESS",
    payload: user
});

export const fetchUserIDPSuccess = (user) => ({
    type: "FETCH_USER_IDP_SUCCESS"
});

export const fetchUserFailure = (error) => ({
    type: "FETCH_USER_FAILURE",
    payload: { error }
});

export function fetchUser(user) {
    return async (dispatch) => {
        dispatch(fetchUserBegin());
        try {
            const loginResponse = await Axios.post("/login", user);
            if (loginResponse && loginResponse.data) {
                return dispatch(fetchUserSuccess(loginResponse.data.sessionData));
            } else {
                return dispatch(fetchUserFailure({ error: "User Not Authenticated" }));
            }
        } catch (err) {
            return dispatch(fetchUserFailure(err));
        }
    };
}

export function fetchSession(user) {
    return async (dispatch) => {
        dispatch(fetchUserBegin());
        try {
            const sessionResponse = await Axios.get("/user_info");
            return dispatch(fetchUserSuccess(sessionResponse.data));
        } catch (err) {
            console.log(err);
            return dispatch(fetchUserFailure(err));
        }
    };
}

export function logoutSession() {
    return async (dispatch) => {
        dispatch(fetchUserBegin());
        try {
            const sessionResponse = await Axios.get("/r-logout");
            return dispatch(fetchUserSuccess(sessionResponse.data));
        } catch (err) {
            return dispatch(fetchUserFailure(err));
        }
    };
}

export function fetchSSOIdentityProvider(email) {
    return async (dispatch) => {
        dispatch(fetchUserBegin());
        try {
            const ssoResponse = await Axios.get("/checkidp/" + email);
            if (ssoResponse && ssoResponse.data) {
                return dispatch(fetchUserIDPSuccess(ssoResponse.data));
            } else {
                return dispatch(
                    fetchUserFailure({
                        error: "The Burwood Cloud Services Portal does not contain configurations for your Identity Provider"
                    })
                );
            }
        } catch (err) {
            return dispatch(fetchUserFailure(err));
        }
    };
}
