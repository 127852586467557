import React, { Component } from "react";

class LoadingData extends Component {
  render() {
    if (!this.props.loading) {
      return null;
    }
    if (!this.props.message) {
      return <div className={this.props.className}>LOADING . . .</div>;
    }
    return <div className={this.props.className}>{this.props.message}</div>;
  }
}

export default LoadingData;
