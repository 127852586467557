//DEPENDENCIES
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./js/store";
import { useWindowEvent } from "./js/hooks/eventListener";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import { ThemeProvider } from "styled-components";

//ROUTES
import App from "./App/AppHome/App";
import Login from "./App/Login/Login";
import ProtectedRoute from "./App/util/ProtectedRoute";
import Reports from "./App/Reports/Reports";
import Incidents from "./App/Incidents/Incidents";
import Devices from "./App/Devices/Devices";
import Events from "./App/Events/Events";
import OpenRequest from "./App/Request/OpenRequest";
import Nav from "./App/Nav/Nav";

const theme = {
    textColor: "white",
    font: "Segoe UI",
    spacing: ".5px",
    weight: "300"
};

const angular_env = process.env.REACT_APP_ANGULAR_FR;

const DefaultContainer = () => {
    useWindowEvent("load", (event) => {
        window.parent.postMessage("Reacted Loaded.", angular_env);
    });

    return (
        <div>
            {process.env.REACT_APP_LOCAL && <Nav />}
            {/* <Nav /> */}
            <Switch>
                <ProtectedRoute exact path="/" component={App} />
                <ProtectedRoute exact path="/reports" component={Reports} />
                <ProtectedRoute exact path="/tickets" component={Incidents} />
                <ProtectedRoute exact path="/devices" component={Devices} />
                <ProtectedRoute exact path="/events" component={Events} />
                <ProtectedRoute exact path="/requests/open_cloud_ticket" component={OpenRequest} />
                <Redirect to="/" />
            </Switch>
        </div>
    );
};

const LoginContainer = () => (
    <div>
        {/* <div className="container"> */}
        <Route exact path="/" render={() => <Redirect to="/login" />} />
        <Route path="/login" component={Login} />
    </div>
);

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Switch>
                    <Route exact path="/(login)" component={LoginContainer} />
                    <ProtectedRoute component={DefaultContainer} />
                </Switch>
            </BrowserRouter>
        </ThemeProvider>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
