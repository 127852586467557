import React, { Component } from "react";
import DefaultComponent from "../util/StyledComponents/DefaultComponent";

class Events extends Component {
  render() {
    return <DefaultComponent>EVENTS</DefaultComponent>;
  }
}

export default Events;
