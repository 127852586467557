const initialState = {
    data: null,
    loading: false,
    sso: false,
    error: null
};

export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case "FETCH_USER_BEGIN":
            return {
                ...state,
                loading: true,
                error: null
            };
        case "FETCH_USER_SUCCESS":
            return {
                ...state,
                loading: false,
                data: action.payload
            };
        case "FETCH_USER_IDP_SUCCESS":
            return {
                ...state,
                loading: false,
                sso: true
            };
        case "FETCH_USER_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        default:
            return state;
    }
}
