import React, { Component } from "react";

class ErrorMessage extends Component {
  render() {
    if (!this.props.error) {
        return null;
      }
      return <div className={this.props.className}>{this.props.error.toString()}</div>;
  }
}

export default ErrorMessage;