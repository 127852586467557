import styled from "styled-components";
import Row from "react-bootstrap/Row";

export const PageContainer = styled.div`
    margin-top: 30px;
    margin-bottom: 30px;
`;

export const CenteredRow = styled(Row)`
    justify-content: center;
    padding-top: ${(props) => (props.padding ? props.padding : "40px")};
    padding-bottom: ${(props) => (props.padding ? props.padding : "40px")};
`;
