import React, { Component } from "react";
import "./LoadingSymbol.scss";

class LoadingSymbol extends Component {
    render() {
        return (
            <div className="loading-panel first align-items-center">
                <div className="icon-wrapper">
                    <svg
                        className="icon-wrapper-child"
                        version="1.1"
                        viewBox="0 0 33.271 22.313"
                    >
                        <path
                            className="cloud"
                            d="M27.244,8.64c0.04-0.319,0.068-0.643,0.068-0.973C27.312,3.432,23.88,0,19.646,0
        c-3.069,0-6.047,2.208-7.047,5.208c-1.625-0.922-3.341-1.195-4.595-0.104c-0.012,0.01-0.019,0.022-0.031,0.033
        c-0.02,0.014-0.042,0.02-0.062,0.034c-1.236,0.875-1.686,2.49-1.336,4.123C6.543,9.292,6.51,9.292,6.51,9.292
        c-3.596,0-6.51,2.914-6.51,6.51c0,3.596,2.914,6.511,6.51,6.511h19.896c3.791,0,6.864-3.073,6.864-6.864
        C33.271,11.941,30.639,9.054,27.244,8.64z"
                        />
                    </svg>
                    <div className="icon">
                        <svg
                            className="icon-child"
                            version="1.1"
                            id="Layer_1"
                            viewBox="0 0 9.652 9.207"
                        >
                            <path
                                fill="#D0E2AA"
                                d="M1.898,4.375c0.117-0.545,0.388-1.03,0.773-1.419l0.862,0.91c0.03,0.032,0.069,0.052,0.11,0.061
        c0.033,0.007,0.067,0.006,0.1-0.002c0.075-0.02,0.132-0.08,0.146-0.154l0.666-3.526C4.567,0.18,4.548,0.113,4.502,0.066
        C4.457,0.018,4.39-0.007,4.323,0.001L0.578,0.312C0.5,0.321,0.435,0.373,0.409,0.444c-0.027,0.071-0.01,0.151,0.044,0.208
        l0.958,0.981c-0.658,0.638-1.116,1.449-1.31,2.358C-0.217,5.479,0.224,7.043,1.28,8.178c0.135,0.145,0.307,0.237,0.488,0.276
        c0.279,0.06,0.582-0.008,0.808-0.208C2.949,7.917,2.519,7.641,2.32,7.269C1.87,6.422,1.7,5.302,1.898,4.375z"
                            />
                            <path
                                fill="#D0E2AA"
                                d="M7.754,4.831C7.638,5.376,7.367,5.861,6.981,6.25L6.119,5.34C6.09,5.309,6.051,5.289,6.009,5.28
        c-0.033-0.007-0.067-0.007-0.1,0.002c-0.075,0.02-0.132,0.08-0.146,0.154L5.097,8.961C5.084,9.027,5.104,9.092,5.15,9.14
        c0.044,0.048,0.112,0.072,0.178,0.064l3.745-0.311c0.077-0.009,0.144-0.06,0.17-0.132c0.026-0.07,0.008-0.151-0.045-0.207
        L8.24,7.573C8.898,6.936,9.356,6.125,9.55,5.215c0.318-1.487-0.123-3.053-1.178-4.188C8.237,0.884,8.065,0.791,7.884,0.752
        C7.605,0.692,7.302,0.76,7.076,0.959c-0.373,0.33,0.059,0.605,0.257,0.979C7.783,2.784,7.953,3.904,7.754,4.831z"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoadingSymbol;
